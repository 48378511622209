import React, { useState } from "react";
import "./css/style.css";

import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/Footer";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Отключаем скроллинг
  };

  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
    document.body.style.overflow = "auto"; // Включаем скроллинг обратно
  };
  return (
    <div className="app">
      <Header onOpenModal={openModal} />
      <Main
        isModalOpen={isModalOpen}
        onCloseModal={closeModal}
        onOpenModal={openModal}
      />
      <Footer />
    </div>
  );
}

export default App;
