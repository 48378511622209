// import React from "react";
// // import { useState } from "react";
// // import { useEffect } from "react";
// import logo from '../img/logo-light.svg'
// import burger from '../img/burger.svg'
// import license from '../assets/license_№_Л035_01273_55_00734009.pdf'

// const handleLogoClick = () => {
//     window.scrollTo({
//         top: 0,
//         behavior: 'smooth', // Добавляем плавное скроллирование
//     });
// }

// class Header extends React.Component {

//     state = {
//         isDropdownOpen: false
//       };

//     handleDropdownToggle = () => {
//         this.setState(prevState => ({
//             isDropdownOpen: !prevState.isDropdownOpen
//         }));
//     };

//     render() {

//         const { isDropdownOpen } = this.state;

//         return(
//             <header className="header">
//                 <img src={logo} onClick={handleLogoClick} alt="logo" className="header__logo" />
//                 <div className="header__content">
//                     <nav className="header__content__nav">
//                         {/* <div href="#course" className="header__content__nav__a">Изменить профиль обучения</div> */}
//                         <a href="#course" className="header__content__nav__a">Курс</a>
//                         <a href="#rates" className="header__content__nav__a"> Выбрать тариф</a>
//                         <a href="#feedback" className="header__content__nav__a">Отзывы</a>
//                         <a href="#teachers" className="header__content__nav__a"> Преподаватели</a>
//                         <a href="#questions" className="header__content__nav__a"> Вопрос-ответ</a>
//                         <div  data-hystmodal='#allSales' className="header__content__nav__a">Акции</div>
//                         <div  data-hystmodal='#profile' className="header__content__nav__a">Другие направления</div>
//                         <a href={license} target="_blank" className="header__content__nav__a"> Лицензия</a>
//                     </nav>
//                     <div className="header__content__button" data-hystmodal='#form'>
//                         <button className="header__content__button__hover">Записаться</button>
//                     </div>
//                     <div
//                         onMouseEnter={this.handleDropdownToggle}
//                         onMouseLeave={this.handleDropdownToggle}
//                         className={`header__nav__dropdown ${isDropdownOpen ? 'active' : ''}`}
//                     >
//                         <div className="header__content__burger">
//                             <img
//                                 src={burger} alt="menu"
//                             />
//                         </div>
//                         <div className="header__content__nav__dropdown-content">
//                             <a href="#course"  className="header__content__nav__dropdown-content__a">Курс</a>
//                             <a href="#rates" className="header__content__nav__dropdown-content__a"> Выбрать тариф</a>
//                             <a href="#feedback" className="header__content__nav__dropdown-content__a">Отзывы</a>
//                             <a href="#teachers" className="header__content__nav__dropdown-content__a"> Преподаватели</a>
//                             <a href="#questions" className="header__content__nav__dropdown-content__a"> Вопрос-ответ</a>
//                             <div  data-hystmodal='#allSales' className="header__content__nav__dropdown-content__a">Акции</div>
//                             <div  data-hystmodal='#profile' className="header__content__nav__dropdown-content__a">Другие направления</div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         )
//         }
// };

// export default Header;

import React, { useState } from "react";
import logo from "../img/logo-light.svg";
import burger from "../img/burger.svg";
import license from "../assets/license_№_Л035_01273_55_00734009.pdf";

const Header = ({ onOpenModal }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Добавляем плавное скроллирование
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <header className="header">
      <img
        src={logo}
        onClick={handleLogoClick}
        alt="logo"
        className="header__logo"
      />
      <div className="header__content">
        <nav className="header__content__nav">
          <a href="#course" className="header__content__nav__a">
            Курс
          </a>
          <a href="#rates" className="header__content__nav__a">
            Выбрать тариф
          </a>
          <a href="#feedback" className="header__content__nav__a">
            Отзывы
          </a>
          <a href="#teachers" className="header__content__nav__a">
            Преподаватели
          </a>
          <a href="#questions" className="header__content__nav__a">
            Вопрос-ответ
          </a>
          <div data-hystmodal="#allSales" className="header__content__nav__a">
            Акции
          </div>
          <div data-hystmodal="#profile" className="header__content__nav__a">
            Другие направления
          </div>
          <a href={license} target="_blank" className="header__content__nav__a">
            Лицензия
          </a>
        </nav>
        <div className="header__content__button" onClick={onOpenModal}>
          <button className="header__content__button__hover">Записаться</button>
        </div>
        <div
          onMouseEnter={handleDropdownToggle}
          onMouseLeave={handleDropdownToggle}
          className={`header__nav__dropdown ${isDropdownOpen ? "active" : ""}`}
        >
          <div className="header__content__burger">
            <img src={burger} alt="menu" />
          </div>
          <div className="header__content__nav__dropdown-content">
            <a
              href="#course"
              className="header__content__nav__dropdown-content__a"
            >
              Курс
            </a>
            <a
              href="#rates"
              className="header__content__nav__dropdown-content__a"
            >
              Выбрать тариф
            </a>
            <a
              href="#feedback"
              className="header__content__nav__dropdown-content__a"
            >
              Отзывы
            </a>
            <a
              href="#teachers"
              className="header__content__nav__dropdown-content__a"
            >
              Преподаватели
            </a>
            <a
              href="#questions"
              className="header__content__nav__dropdown-content__a"
            >
              Вопрос-ответ
            </a>
            <div
              data-hystmodal="#allSales"
              className="header__content__nav__dropdown-content__a"
            >
              Акции
            </div>
            <div
              data-hystmodal="#profile"
              className="header__content__nav__dropdown-content__a"
            >
              Другие направления
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
