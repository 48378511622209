import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
// import { YMInitializer } from 'react-yandex-metrika';
// import ym from 'react-yandex-metrika';
import * as Yup from "yup";

function LeadForm() {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNameForbidden, setIsNameForbidden] = useState(false);

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const apiUrl = "/send_lead";
      const requestData = {
        phone: values.phone,
        name: values.name,
      };

      if (isNameForbidden) {
        // Если имя запрещено, устанавливаем обманное сообщение об успешной отправке
        setStatus({ success: true });
      } else {
        // Иначе отправляем запрос на сервер
        const response = await axios.post(apiUrl, requestData);
        if (response.data.success) {
          console.log("Lead sent", response.data);
          setStatus({ success: true });
        } else {
          setStatus({ success: false });
          console.error("Error sending lead", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error sending lead", error);
      setStatus({ success: false });
    }
  };

  const forbiddenNames = ["пидор", "сука", "pidor", "suka", "жопа", "zhopa"];

  const forbiddenPhoneNumbers = [
    "+7(111) 111-11-11",
    "+7(999) 999-99-99",
    "+7(123) 456-78-90",
    "+7(098) 765-43-21",
    "+7(222) 222-22-22",
    "+7(333) 333-33-33",
    "+7(444) 444-44-44",
    "+7(555) 555-55-55",
    "+7(666) 666-66-66",
    "+7(777) 777-77-77",
    "+7(888) 888-88-88",
    "81111111111",
    "89999999999",
    "81234567890",
    "80987654321",
    "82222222222",
    "83333333333",
    "84444444444",
    "85555555555",
    "86666666666",
    "87777777777",
    "88888888888",
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("*Заполните поле")
      .matches(
        /^[A-Za-zА-Яа-яЁё\s]+$/,
        "*Имя не может содержать цифры или символы"
      )
      .test("forbidden-name", "*Введите корректное имя", (value) => {
        if (forbiddenNames.includes(value)) {
          setIsNameForbidden(true); // Устанавливаем, что имя запрещено
          return true; // Возвращаем true, чтобы считать валидацию успешной
        }
        setIsNameForbidden(false); // Устанавливаем, что имя не запрещено
        return true; // Возвращаем true, чтобы считать валидацию успешной
      }),
    phone: Yup.string()
      .required("*Заполните поле")
      .matches(
        /^8\d{10}$/,
        "*Введите корректный российский номер телефона, начинающийся с 8 и содержащий 11 цифр"
      )
      .notOneOf(
        forbiddenPhoneNumbers,
        "*Введите корректный российский номер телефона"
      ),
  });

  const formatPhone = (value) => {
    const digits = value.replace(/\D/g, ""); // Убираем все нечисловые символы
    if (digits.startsWith("8")) {
      return digits.slice(0, 11); // Ограничиваем до 11 цифр
    } else if (digits.startsWith("7")) {
      return "8" + digits.slice(1, 11); // Заменяем первую цифру 7 на 8, если номер начинается с 7
    } else {
      return "8" + digits.slice(0, 10); // Если ввод начался с другой цифры, начинаем с 8
    }
  };

  return (
    <Formik
      initialValues={{ name: "", phone: "" }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = "*Заполните поле";
        }
        if (!values.phone || !/^8\d{10}$/.test(values.phone)) {
          errors.phone =
            "*Введите корректный российский номер телефона, начинающийся с 8 и содержащий 11 цифр";
        }
        setIsFormValid(Object.keys(errors).length === 0);
        return errors;
      }}
    >
      {({ isSubmitting, status, values, setFieldValue, isValid }) => (
        <Form className="main__section__form__layout">
          {/* <YMInitializer accounts={[94963600]} options={{ webvisor: true }} /> */}
          <h1 className="main__section__form__layout__title">
            Заявка на пробное занятие
          </h1>
          <p className="main__section__form__layout__text">
            Оставьте свой номер телефона, наш менеджер перезвонит и запишет Вас
            на пробное занятие в удобное время
          </p>
          <div className="main__section__form__layout__item">
            <Field
              type="text"
              id="name"
              placeholder="Ваше имя"
              name="name"
              className="main__section__form__layout__item__input"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <div className="main__section__form__layout__item">
            <Field
              name="phone"
              className="main__section__form__layout__item__input"
              placeholder="Введите номер телефона"
              value={values.phone}
              onChange={(e) =>
                setFieldValue("phone", formatPhone(e.target.value))
              }
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="main__section__form__layout__item__error"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting || !isFormValid}
            className={`main__section__form__layout__button ${
              isSubmitting || !isFormValid ? "disabled" : ""
            }`}
          >
            Отправить
          </button>
          {status && (status.success || status.fakeSuccess) ? (
            <div className="main__section__form__layout__success">
              <h3 className="main__section__form__layout__success__title">
                {status.fakeSuccess
                  ? "Ваш запрос обманут"
                  : "Спасибо за Вашу заявку"}
              </h3>
              <p className="main__section__form__layout__success__p">
                {status.fakeSuccess
                  ? "Мы получили ваш запрос, но не можем его принять"
                  : "Наш менеджер скоро свяжется с Вами!"}
              </p>
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}

export default LeadForm;
