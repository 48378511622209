import React from "react";

import waves3 from "../img/waves3.svg";
import smearImg1 from "../img/smearImg1.svg";
import smearImg2 from "../img/smearImg2.svg";
import smear1 from "../img/smear1.svg";
import smear2 from "../img/smear2.svg";
import point from "../img/point.svg";
import resultsImg from "../img/resultsImg.svg";

function About({ onOpenModal }) {
  return (
    <>
      <div className="main__section__about">
        <div className="main__section__about__container">
          <div className="main__section__about__container__group1">
            <div className="main__section__about__container__group1__left">
              <div className="main__section__about__container__group1__left__title">
                <img
                  loading="lazy"
                  src={smear1}
                  alt="point"
                  className="main__section__about__container__group1__left__title__img"
                />
              </div>
              <div className="main__section__about__container__group1__left__points">
                <div className="main__section__about__container__group1__left__points__point">
                  <img
                    loading="lazy"
                    src={point}
                    alt="point"
                    className="main__section__about__container__group1__left__points__point__img"
                  />
                  <h2 className="main__section__about__container__group1__left__points__point__text">
                    Получить первые знания по китайскому языку
                  </h2>
                </div>
                <div className="main__section__about__container__group1__left__points__point">
                  <img
                    loading="lazy"
                    src={point}
                    alt="point"
                    className="main__section__about__container__group1__left__points__point__img"
                  />
                  <h2 className="main__section__about__container__group1__left__points__point__text">
                    Выяснить, нравится ли Вам этот язык
                  </h2>
                </div>
                <div className="main__section__about__container__group1__left__points__point">
                  <img
                    loading="lazy"
                    src={point}
                    alt="point"
                    className="main__section__about__container__group1__left__points__point__img"
                  />
                  <h2 className="main__section__about__container__group1__left__points__point__text">
                    Расширить свой кругозор и узнать новое
                  </h2>
                </div>
              </div>
            </div>
            <div className="main__section__about__container__group1__right">
              <img
                loading="lazy"
                src={smearImg1}
                alt="smear"
                className="main__section__about__container__group1__right__img"
              />
            </div>
          </div>
          <div className="main__section__about__container__group2">
            <div className="main__section__about__container__group2__left">
              <img
                loading="lazy"
                src={smearImg2}
                alt="smear"
                className="main__section__about__container__group2__left__img"
              />
            </div>
            <div className="main__section__about__container__group2__right">
              <div className="main__section__about__container__group2__right__title">
                <img
                  loading="lazy"
                  src={smear2}
                  alt="point"
                  className="main__section__about__container__group2__right__title__img"
                />
              </div>
              <div className="main__section__about__container__group2__right__points">
                <div className="main__section__about__container__group2__right__points__point">
                  <img
                    loading="lazy"
                    src={point}
                    alt="point"
                    className="main__section__about__container__group2__right__points__point__img"
                  />
                  <h2 className="main__section__about__container__group2__right__points__point__text">
                    Индивидуальные занятия <br />2 раза в неделю{" "}
                  </h2>
                </div>
                <div className="main__section__about__container__group2__right__points__point">
                  <img
                    loading="lazy"
                    src={point}
                    alt="point"
                    className="main__section__about__container__group2__right__points__point__img"
                  />
                  <h2 className="main__section__about__container__group2__right__points__point__text">
                    Групповые занятия <br />2 раза в неделю{" "}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main__section__about__container__button"
            onClick={onOpenModal}
          >
            <button className="main__section__about__container__button__hover">
              Попробовать бесплатный урок
            </button>
          </div>
        </div>
        <img
          loading="lazy"
          src={waves3}
          alt="waves"
          className="main__section__about__waves"
        />
      </div>
      <div className="main__section__results">
        <div className="main__section__results__container">
          <h2 className="main__section__results__container__title">
            Изучая китайский язык в нашей онлайн-школе, <br />
            уже по окончании первого уровня Вы или Ваш ребенок:
          </h2>
          <div className="main__section__results__container__content">
            <div className="main__section__results__container__content__points">
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Освоите тонкости произношения, тональность
                </h2>
              </div>
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Выучите иероглифы для начальной разговорной лексики
                </h2>
              </div>
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Сможете говорить на китайском языке, например: познакомиться,
                  представиться и кратко рассказать о себе, о своей семье,
                  увлечениях и т.д.
                </h2>
              </div>
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Научитесь правильно писать китайские иероглифы
                </h2>
              </div>
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Освоите самые нужные грамматические структуры, которые
                  позволяют строить простые предложения и задавать различные
                  виды вопросов
                </h2>
              </div>
              <div className="main__section__results__container__content__points__point">
                <img
                  loading="lazy"
                  src={point}
                  alt="point"
                  className="main__section__results__container__content__points__point__img"
                />
                <h2 className="main__section__results__container__content__points__point__text">
                  Познакомитесь с культурными особенностями Китая, например,
                  каждое лето традиционно проходит «Праздник драконьих лодок -
                  端午», про него подробнее будет рассказано в курсе
                </h2>
              </div>
            </div>
            <img
              loading="lazy"
              src={resultsImg}
              alt="results"
              className="main__section__results__container__content__img"
            />
          </div>
          <div
            className="main__section__results__container__button"
            onClick={onOpenModal}
          >
            <button className="main__section__results__container__button__hover">
              Попробовать бесплатный урок
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
